import {Button, Card, Col, Divider, Form, Modal, notification, Row, Tabs, Tag} from "antd";
import TextArea from "antd/es/input/TextArea";
import  { PlusSquareOutlined } from "@ant-design/icons";
import StatusComponent from "./tabs/StatusComponent";
import NutritionComponent from "./tabs/NutritionComponent";
import HealthComponent from "./tabs/HealthComponent";
import ProtectionComponent from "./tabs/ProtectionComponent";
import useFetch from "../../../../../hooks/useFetch";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import useMessageUser from "../../../../../hooks/useMessageUser";
import EducationComponent from "./tabs/EducationComponent";
import { CgGirl } from "react-icons/cg";
import {useEffect, useState} from "react";
import { TbMoodBoy } from "react-icons/tb";
import moment from "moment";
import Followers from "./Followers";
import {useTranslation} from "react-i18next";
import {getScopes} from "../../helperComms";

const { TabPane } = Tabs;

function DetailSc5({ openDetailSc, setOpenDetailSc, scNumber, getTabla }: {openDetailSc: boolean, setOpenDetailSc: Function, scNumber: string, getTabla: Function }) {
  const [form] = Form.useForm();
  const scopes = getScopes();
  const { fetchApi } = useFetch();
  const [dataSc, setData] = useState<any>({});
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();
  const [formState, setFormState] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (openDetailSc) {
      getDetail();
      getStatusList();
    }
  }, [openDetailSc]);

  useEffect(() => {
    if (dataSc) {
      const statusSc = dataSc?.statusSc || {};
      const nutritionEntity = dataSc?.nutritionEntity || null;
      const healthEntity = dataSc?.healthEntity || null;
      const protectionEntity = dataSc?.protectionEntity || null;
      const educationScEntity = dataSc?.educationScEntity || null;
      const followScEntities = dataSc?.followScEntities || [];
      setDataSource(followScEntities);

      form.setFieldsValue({
        staIsNoi: statusSc?.staIsNoi,
        statusCatalog: statusSc?.statusCatalogEntity?.scaId || 1,
        staDateNoi: statusSc?.staDateNoi && moment(statusSc?.staDateNoi),
        staChangeDate: statusSc?.staChangeDate ? moment(statusSc?.staChangeDate) : moment(),

        nutEnable: (nutritionEntity !== null),
        nutMalnutrition: nutritionEntity?.nutMalnutrition || false,
        typeMalnutrition: nutritionEntity?.typeMalnutritionEntity?.tmaId,
        nutDetail: nutritionEntity?.nutDetail,

        heaEnable: (healthEntity !== null),
        heaHealthEmergency: healthEntity?.heaHealthEmergency || false,
        heaChronicDisease: healthEntity?.heaChronicDisease || false,
        heaDisability: healthEntity?.heaDisability || false,
        heaDetails: healthEntity?.heaDetails || null,

        familarUnion: protectionEntity?.familarUnion?.funId || 1,
        scProtectionFamily: protectionEntity?.scProtectionFamilyEntity?.spfId || 1,
        pscDetail: protectionEntity?.pscDetail,
        pscSexualViolence: protectionEntity?.pscSexualViolence || false,
        pscLeaving: protectionEntity?.pscLeaving || false,
        pscPhysicalViolence: protectionEntity?.pscPhysicalViolence || false,
        pscPregnant: protectionEntity?.pscPregnant || false,
        pscEnable: (protectionEntity !== null),

        grades: educationScEntity?.grades?.graId,
        noStudyReason: educationScEntity?.noStudyReasonEntity?.nsrId,
        eduDetails: educationScEntity?.eduDetails,
        eduPriorizedScholarship: educationScEntity?.eduPriorizedScholarship || false,
        eduEnable: (educationScEntity !== null),
      });

      setFormState({
        ascMail: dataSc.ascMail || null,
        status: {
          statusCatalog: statusSc?.statusCatalogEntity?.scaId || 1,
          staIsNoi: statusSc?.staIsNoi || false,
          staDateNoi: statusSc?.staDateNoi || statusSc?.staIsNoi ? moment() : null,
          staChangeDate: statusSc?.staChangeDate ? moment(statusSc?.staChangeDate) : moment(),
        },
        nutrition: {
          typeMalnutrition: nutritionEntity?.typeMalnutritionEntity?.tmaId || null,
          nutEnable: (nutritionEntity !== null),
          nutMalnutrition: nutritionEntity?.nutMalnutrition || false,
          nutDetail: nutritionEntity?.nutDetail || null,
        },
        health: {
          heaEnable: (healthEntity !== null),
          heaHealthEmergency: healthEntity?.heaHealthEmergency || false,
          heaChronicDisease: healthEntity?.heaChronicDisease || false,
          heaDisability: healthEntity?.heaDisability || false,
          heaDetail: healthEntity?.heaDetails || null,
        },
        protection: {
          familarUnion: protectionEntity?.familarUnion?.funId || 1,
          scProtectionFamily: protectionEntity?.scProtectionFamilyEntity?.spfId || 1,
          pscEnable: (protectionEntity !== null),
          pscPregnant: protectionEntity?.pscPregnant || false,
          pscSexualViolence: protectionEntity?.pscSexualViolence || false,
          pscLeaving: protectionEntity?.pscLeaving || false,
          pscPhysicalViolence: protectionEntity?.pscPhysicalViolence || false,
          pscDetail: protectionEntity?.pscDetail || null,
        },
        education: {
          grades: educationScEntity?.grades?.graId || null,
          noStudyReason: educationScEntity?.noStudyReasonEntity?.nsrId || null,
          eduEnable: (educationScEntity !== null),
          eduPriorizedScholarship: educationScEntity?.eduPriorizedScholarship || false,
          eduDetails: educationScEntity?.eduDetails || null,
        },
        follow: followScEntities || [],
      });
      setLoading(false);
    }
  }, [dataSc]);

  const getStatusList = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/status-catalog`,
      method: 'GET'
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setStatusOptions(data?.data || []);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const getDetail = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/find_sc_activos_by_number`,
      data: { scNumber },
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          setData(data?.data || {});
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const sendEmail = () => {
    const dataSend: FetchApiInterface = {
      url: `/comms/send_email_to_cw`,
      data: { scNumber },
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const cleanFormState = (state: any) => {
    const cleanedState: any = {};

    for (const key in state) {
      if (typeof state[key] === 'object' && state[key] !== null) {
        const cleanedSection: any = {};
        for (const subKey in state[key]) {
          if (state[key][subKey] !== null) {
            cleanedSection[subKey] = state[key][subKey];
          }
        }

        if (Object.keys(cleanedSection).length > 0) {
          cleanedState[key] = cleanedSection;
        }
      } else if (state[key] !== null) {
        cleanedState[key] = state[key];
      }
    }

    return cleanedState;
  };

  const onFinish = (values: any) => {
    const cleanedData = cleanFormState(formState);
    const statusCatalog: any = statusOptions.find((item: any) => item.scaId === cleanedData?.status?.statusCatalog);
    if ((statusCatalog?.scaName === 'closed' || statusCatalog?.scaName === 'resolved' || statusCatalog?.scaName === 'closedAfterVisit') && !dataSource.length) {
      notification.error({
        message: 'Error',
        description: 'El caso no puede ser cerrado sin un seguimiento.',
      });
      return;
    }
    if (!cleanedData?.education?.eduEnable && !cleanedData?.health?.heaEnable && !cleanedData?.nutrition?.nutEnable && !cleanedData?.protection?.pscEnable && statusCatalog?.scaName === 'open') {
      notification.error({
        message: 'Error',
        description: 'Al menos una sección debe estar habilitada.',
      });
      return;
    }
    const dataSend: FetchApiInterface = {
      url: `/comms/save_active_sc`,
      data: {scNumber, ...cleanedData, follow: dataSource || [],},
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
          setOpenDetailSc(false);
          getTabla();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      const dataReason = reason?.data || {};
      // Recorremos el objeto de errores y presentamos en una lista de notification
      notification.error({
        message: 'Error',
        description: (
          <div>
            <p>Por favor revisar los siguientes errores:</p>
            <ul>
              {
                Object.keys(dataReason).map((key: any) => (
                  <li key={key}>{key}: {dataReason[key]}</li>
                ))
              }
            </ul>
          </div>
        ),
      });
      showMessage(reason?.message, 'error');
    });
  }

  const calcYear = () => {
    if (!dataSc?.ascScBornDate) return;
    // calculamos la edad en años del SC ' ascScBornDate
    const bornDate = new Date(dataSc?.ascScBornDate);
    const today = new Date();
    const diff = today.getTime() - bornDate.getTime();
    const age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  }

  const handleChange = (section: any, key: any, value: any) => {
    if (!section) {
      setFormState((prevState: any) => ({
        ...prevState,
        [key]: value,
      }));
      return;
    }
    setFormState((prevState: any) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [key]: value,
      },
    }));
  };

  const getAllTags = () => {
    const tags = [];

    if (dataSc?.educationScEntity !== null) {
      tags.push(<Tag color="blue" key="educationScEntity">{t('education')}</Tag>);
    }
    if (dataSc?.healthEntity !== null) {
      tags.push(<Tag color="blue" key="healthEntity">{t('health')}</Tag>);
    }
    if (dataSc?.nutritionEntity !== null) {
      tags.push(<Tag color="blue" key="nutritionEntity">{t('nutrition')}</Tag>);
    }
    if (dataSc?.protectionEntity !== null) {
      tags.push(<Tag color="blue" key="protectionEntity">{t('protection')}</Tag>);
    }

    return tags;
  };

  const onFinishFailed = (errorInfo: any) => {
    // Mapear los nombres de los campos a sus traducciones
    const translatedErrorInfo = {
      ...errorInfo,
      errorFields: errorInfo.errorFields.map((field: any) => ({
        ...field,
        name: field.name.map((name: string) => t(name)),
      })),
    };

    // Lanzar una notificación para cada error
    translatedErrorInfo.errorFields.forEach((field: any) => {
      field.errors.forEach((error: string) => {
        notification.error({
          message: t('titleErrorFormValidation'),
          description: `${field.name.join(', ')}: ${error}`,
        });
      });
    });
  };

  return (
    <Modal
      title={dataSc?.ascMail ?? ' '}
      open={openDetailSc}
      onOk={() => form.submit()}
      onCancel={() => setOpenDetailSc(false)}
      width={1000}
      okText={t('save')}
      cancelText={t('cancel')}
    >
      <Form form={form} disabled={!scopes.includes('COMP_5_SAVE')} layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} >
        <Row gutter={[20, 20]}>
          <Col span={24} md={8}>
            <Card loading={loading} style={{ borderRadius: 10 }}>
              <div style={{ textAlign: 'center', marginBottom: 16 }}>
                {
                  (dataSc?.ascScGenre === 'Female') ? <CgGirl size={80} /> : <TbMoodBoy size={80} />
                }
                <h2>{dataSc?.ascScName}</h2>
                <h3>{calcYear()} {t('yearsOld')}</h3>
                <Divider />
              </div>
              <p><strong>{t('scNumber')}:</strong> {dataSc?.ascScNumber}</p>
              <p><strong>{t('birthDate')}:</strong> {dataSc?.ascScBornDate}</p>
              <p><strong>{t('finding')}:</strong> {getAllTags()}</p>
              <p><strong>{t('status')}:</strong> <Tag color="green"> {dataSc?.statusSc?.statusCatalogEntity?.scaName ?? '-'} </Tag></p>
              <p><strong>{t('openingDate')}:</strong> {dataSc?.createdAtComp5?.substring(0, 10)}</p>
              <p><strong>{t('modificationDate')}:</strong> {dataSc?.updatedAtComp5?.substring(0, 10)}</p>
              <p><strong>{t('pu')}:</strong> {dataSc?.ascPuCode}</p>
              <p><strong>{t('community')}:</strong> {dataSc?.ascLevel}</p>
              <p><strong>{t('fdc')}:</strong> {dataSc?.ascCwCode} - {dataSc?.ascCw}</p>
              {
                (scopes.includes('COMP_5_EMAIL')) && (
                  <>
                    <p><strong>{t('message')}:</strong></p>
                    <TextArea placeholder={t('enterTaskDescription')} rows={4} style={{ marginBottom: 16 }} onChange={(e) => handleChange(null, 'ascMail', e.target.value)}>
                      {dataSc?.ascMessage}
                    </TextArea>
                    <Button type="primary" shape='round' onClick={sendEmail} block>{t('sendAlertEmail')}</Button>
                  </>
                )
              }
            </Card>
          </Col>
          <Col span={24} md={16}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Card loading={loading} style={{ borderRadius: 10 }}>
                  <Row>
                    <Col span={24}>
                      <Tabs defaultActiveKey="1">
                        <TabPane tab={t('status')} key="1">
                          <StatusComponent form={form} handleChange={handleChange} formState={formState} statusOptions={statusOptions} />
                        </TabPane>
                        <TabPane tab={t('nutrition')} key="2">
                          <NutritionComponent form={form} handleChange={handleChange} formState={formState} />
                        </TabPane>
                        <TabPane tab={t('health')} key="3">
                          <HealthComponent form={form} handleChange={handleChange} formState={formState} />
                        </TabPane>
                        <TabPane tab={t('protection')} key="4">
                          <ProtectionComponent form={form} handleChange={handleChange} formState={formState} />
                        </TabPane>
                        <TabPane tab={t('education')} key="5">
                          <EducationComponent form={form} handleChange={handleChange} formState={formState} />
                        </TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Followers setDataSource={setDataSource} dataSource={dataSource} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default DetailSc5;
