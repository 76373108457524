import {Button, Dropdown, Menu, MenuProps, message, Space, Spin} from "antd";
import { DownOutlined } from '@ant-design/icons';
import {FetchApiInterface} from "../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../interfaces/Response.interface";
import {useContext, useEffect, useState} from "react";
import useFetch from "../../hooks/useFetch";
import {useTranslation} from "react-i18next";
import useSetLanguage from "../../hooks/useSetLanguage";
import GlobalContex from "../../Store/GlobalContext";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

function ChangeLanguage() {

  const { fetchApi } = useFetch();
  const { currentLanguage: { language, setLanguage }, updateListLanguages: { updateLis } } = useContext(GlobalContex);
  const { getLanguage, loadLanguage } = useSetLanguage();
  const [ loading, setLoading ] = useState(false);
  const { i18n } = useTranslation();
  const { xxl } = useBreakpoint();
  const [languages, setLanguages] = useState<Array<any>>( []);

  useEffect(() => {
    if (updateLis) {
      getLanguages();
    }
  }, [updateLis]);

  useEffect(() => {
    if (languages?.length > 0 && Boolean(language) && !localStorage.getItem('lng-selected')) {
      const user = JSON.parse(localStorage.getItem('user') as string);
      setLanguage(user?.user?.language || languages[0]['data']);
      getLanguage(user?.user?.language?.lanName || 'English');
    }
  }, [languages]);

  useEffect(() => {
    if (localStorage.getItem('lng-selected')) {
      const lngLocal = JSON.parse(localStorage.getItem('lng-selected') as string);
      setLanguage(lngLocal);
      getLanguage(lngLocal?.lanName || 'English');
    }
    getLanguages();
  }, []);

  const getLanguages = () => {
    setLoading(true);
    const dataSend: FetchApiInterface = {
      url: '/language/get_language',
      data: {
        statusRegister: true
      }
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const list: Array<any> = [];
          data?.data?.forEach((item: any) => {
            list.push({
              key: item?.lanId,
              label: item?.lanName,
              data: item,
            })
          });
          setLanguages(list);
        } else {
          message.warning(data.message);
        }
      }
    ).catch((reason: any) => {
      const msg = (reason?.message && reason.message.includes('code_')) ? reason?.message : 'Server error.';
      message.error(msg);
    }).finally(() => setLoading(false));
  }

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setLanguage(e?.item?.props?.data);
    getLanguage(e?.item?.props?.data?.lanName || 'English');
  };

  return (
    <Spin spinning={loading || loadLanguage}>
      <Dropdown overlay={<Menu onClick={handleMenuClick} items={languages} />}>
        <Button className='btn-border-radius'>
          <Space>
            { (xxl) ? language?.lanName : language?.lanName?.substring(0, 2) }
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Spin>
  )
}

export default ChangeLanguage;
