// PROD
/*export const API_BASE_URL = 'https://backabc.plan-roa.org';
export const HOST_URL = 'https://abc.plan-roa.org/';
export const CLOSE_SESSION = 'https://plan-international.okta-emea.com/login/signout';*/
// QA
export const API_BASE_URL = 'https://qabackcomms.plan-roa.org';
export const HOST_URL = 'https://qacomms.plan-roa.org/';
export const CLOSE_SESSION = 'https://plan-international.oktapreview.com/oauth2/default/v1/logout';
// DEV
/*export const API_BASE_URL = 'https://qabackcomms.plan-roa.org';
export const HOST_URL = 'http://localhost:8080/authorization-code/callback';
export const CLOSE_SESSION = 'https://plan-international.oktapreview.com/oauth2/default/v1/logout';*/
export const AUTH_TOKEN = 'auth_token';
export const AUTH_SCOPES = 'scopes';
export const REFRESH_TOKEN = 'token_refresh';
export const USER_INFO = 'user';
