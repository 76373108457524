import {
  AutoComplete,
  Breadcrumb,
  Button,
  Card, Checkbox,
  Col, Dropdown,
  Form, Input,
  Layout, Menu,
  Modal, Popconfirm,
  Row,
  Space, Spin,
  Table,
  Tooltip,
  Typography
} from "antd";
import {useEffect, useState} from "react";
import {
  EllipsisOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import DpaSelected from "../../../../Components/DpaSelected";
import {IMonitoring} from "../../models";
import useFetch from "../../../../../hooks/useFetch";
import useMessageUser from "../../../../../hooks/useMessageUser";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import DrawerProjectC2 from "../../components/DrawerProjectC2";
import useExport from "../../../../../hooks/comms/useExport";
import DetailSc5 from "../../components/compromisos/DetailSc5";
import debounce from 'lodash/debounce';
import Filters from "../../components/compromisos/Filters";
import {useTranslation} from "react-i18next";
import {getScopes} from "../../helperComms";

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { Search } = Input;
const Compromiso5 = () => {
  const { t } = useTranslation();
  const scopes = getScopes();

  const calcYear = (ascScBornDate: string) => {
    // calculamos la edad en años del SC ' ascScBornDate
    const bornDate = new Date(ascScBornDate);
    const today = new Date();
    const diff = today.getTime() - bornDate.getTime();
    const age = new Date(diff);
    return Math.abs(age.getUTCFullYear() - 1970);
  }

  const getAllTags = (dataSc: any) => {
    let tags = '';

    if (dataSc?.educationScEntity?.ascId) {
      tags = tags + 'EDUCACIÓN/';
    }
    if (dataSc?.healthEntity !== null) {
      tags = tags + 'SALUD/';
    }
    if (dataSc?.nutritionEntity !== null) {
      tags = tags + 'NUTRICIÓN/';
    }
    if (dataSc?.protectionEntity !== null) {
      tags = tags + 'PROTECCIÓN/';
    }
    // eliminamos el último caracter '/' de la cadena
    tags = tags.slice(0, -1);
    return tags;
  };

  const initialColumns = [
    {
      title: t('scNumber'),  // Reemplaza 'SC Number'
      dataIndex: 'ascScNumber',
      key: 'ascScNumber',
      visible: true,
      fixed: 'left'
    },
    {
      title: t('community'),  // Reemplaza 'Comunidad'
      dataIndex: 'ascLevel',
      key: 'ascLevel',
      visible: true,
    },
    {
      title: t('openingDate'),  // Reemplaza 'Fecha de Apertura'
      dataIndex: 'createdAtComp5',
      key: 'createdAtComp5',
      visible: true,
      render: (item: any) => {
        return(`${item?.substring(0, 10)}`);
      }
    },
    {
      title: t('modificationDate'),  // Reemplaza 'Fecha modificación'
      dataIndex: 'updatedAtComp5',
      key: 'updatedAtComp5',
      visible: true,
      render: (item: any) => {
        return(`${item?.substring(0, 10)}`);
      }
    },
    {
      title: t('noi'),  // Reemplaza 'NOI'
      dataIndex: 'statusSc',
      key: 'statusSc',
      visible: true,
      render: (item: any) => {
        return(`${item?.staIsNoi ? t('yes') : t('no')}`);
      }
    },
    {
      title: t('name'),  // Reemplaza 'Nombre'
      dataIndex: 'ascScName',
      key: 'ascScName',
      visible: true,
    },
    {
      title: t('prioritizedForScholarship'),  // Reemplaza 'Priorizada para beca'
      dataIndex: 'educationScEntity',
      key: 'educationScEntity',
      visible: true,
      render: (item: any) => {
        return(`${item?.eduPriorizedScholarship ? t('yes') : t('no')}`);
      }
    },
    {
      title: t('age'),  // Reemplaza 'Edad'
      dataIndex: 'ascScBornDate',
      key: 'ascScBornDate',
      visible: true,
      render: (ascScBornDate: any) => {
        return(`${calcYear(ascScBornDate)} ${t('years')}`);  // Reemplaza 'Años'
      }
    },
    {
      title: t('gender'),  // Reemplaza 'Género'
      dataIndex: 'ascScGenre',
      key: 'ascScGenre',
      visible: true,
    },
    {
      title: t('fdc'),  // Reemplaza 'FDC'
      dataIndex: 'ascCwCode',
      key: 'ascCwCode',
      visible: true,
      render: (ascScBornDate: any, row: any) => {
        return(`${row?.ascCwCode} - ${row?.ascCw}`);
      }
    },
    {
      title: t('ballotStatus'),  // Reemplaza 'Estatus boleta'
      dataIndex: 'statusSc',
      key: 'statusSc',
      visible: true,
      render: (item: any, row: any) => {
        return(`${item?.statusCatalogEntity?.scaName}`);
      }
    },
    {
      title: t('findingType'),  // Reemplaza 'Tipo de hallazgo'
      dataIndex: 'statusSc',
      key: 'statusSc',
      visible: true,
      render: (item: any, row: any) => {
        return(`${getAllTags(row)}`);
      }
    },
    {
      title: t('actions'),  // Reemplaza 'Acciones'
      dataIndex: 'ascScNumber',
      key: 'ascScNumber',
      visible: true,
      render: (ascScNumber: any, record: any) => {
        return(
          <Space direction='vertical'>
            <Button size='small' block type='primary' onClick={() => openDetailEdit(record)}>{ t('btnDetail') }</Button>
            {
              (scopes.includes('COMP_5_ELMINAR')) && (
                <Popconfirm
                  title={t('titleDeleteConfirmComp5')}  // Reemplaza el texto de confirmación de eliminación
                  okText={t('btnYes')}  // Reemplaza 'Sí'
                  cancelText={t('btnNo')}  // Reemplaza 'No'
                  onConfirm={() => deleteComp5(ascScNumber)}
                >
                  <Button size='small' block danger>{ t('btnDelete') }</Button>
                </Popconfirm>
              )
            }
          </Space>
        );
      }
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filterApply, setFilterApply] = useState<any>({});
  const [filterApplyExcel, setFilterApplyExcel] = useState<any>({});
  const [itemRegister, setItemRegister] = useState<any>({});
  const [dataFilters, setDataFilters] = useState<any>({});
  const [searchText, setSearchText] = useState<string>('');
  const [columns, setColumns] = useState<Array<any>>(initialColumns);
  const [dataSource, setDataSource] = useState<Array<IMonitoring>>([]);
  const { fetchApi, loading } = useFetch();
  const [openModalFilterExport, setOpenModalFilterExport] = useState(false);
  const [openDetailSc, setOpenDetailSc] = useState(false);
  const [scNumber, setScNumber] = useState('');
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { showMessage }  = useMessageUser();

  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 20,
    total: 0,
  });
  const [form] = Form.useForm();
  const { exportExcel, downloading } = useExport();

  useEffect(() => {
    if (!openDetailSc) {
      setScNumber('');
    }
  }, [openDetailSc]);

  useEffect(() => {
    form.setFieldValue('dpasExcel', null);
    setFilterApplyExcel({});
  }, [openModalFilterExport]);

  const checkProjectDrawer = (item: any) => {
    setItemRegister(item);
    setOpenDrawer(true);
  }

  useEffect(() => {
    if (!searchText) {
      const newFilterApply = { ...dataFilters };
      delete newFilterApply.search;
      setDataFilters(newFilterApply);
    }
  }, [searchText]);

  useEffect(() => {
    if (!openDrawer) {
      getTabla();
    }
  }, [dataFilters, openDrawer, filterApply]);

  const handleTableChange = (pagination: any) => {
    getTabla(pagination.current, pagination.pageSize);
  };

  const deleteComp5 = (scNumber: string) => {
    const dataSend: FetchApiInterface = {
      url: `/comms/delete_sc_activos_by_number`,
      data: { scNumber },
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
          getTabla();
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const getTabla = (page = 1, size = 20) => {
    console.log('filterApply', filterApply);
    const dateSend = { ...filterApply, search: searchText };
    const dataSend: FetchApiInterface = {
      url: `/comms/find_sc_activos?page=${page - 1}&size=${size}`,
      data: dateSend,
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setDataSource(dataResponse?.content || []);
          setPagination({
            ...pagination,
            current: data?.data?.pageable?.pageNumber + 1,
            total: data?.data?.totalElements,
            pageSize: size,
          });
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const handleSearch = (value: string) => {
    if (value) {
      setSearchText(value.trim());
      setDataFilters((prevFilters: any) => ({ ...prevFilters, search: value.trim() }));
    }
  };

  // Función para manejar el cambio en el estado de visibilidad de las columnas
  const handleVisibleChange = (e: any, columnName: any) => {
    const newColumns = columns.map(column => {
      if (column.key === columnName) {
        return { ...column, visible: e.target.checked };
      }
      return column;
    });
    setColumns(newColumns);
  };

  const visibleColumns = columns.filter(column => column.visible);

  // Menú para el Dropdown con Checkboxes para cada columna
  const menu = (
    <Menu>
      {columns.map(column => (
        <Menu.Item key={column.key}>
          <Checkbox
            checked={column.visible}
            onChange={e => handleVisibleChange(e, column.key)}
          >
            {column.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  // El Dropdown que muestra el menú
  const dropdown = (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button type='primary' shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );

  const onFinish = (values: any) => {
    // Agregar age unicamente si existe values?.age para evitar error en el backend
    if (values?.age) {
      values.age = { desde: values?.age[0], hasta: values?.age[1] };
    }
    setFilterApply({ ...values });
    setOpenModal(false);
  }

  const cleanFilters = () => {
    setSearchText('');
    form.resetFields();
    setDataFilters({});
    setFilterApply({});
    setOpenModal(false);
  }
  const exportExcelHanddle = async () => {
    const url = `/comms/comp_5/download-excel`;
    setOpenModalFilterExport(false);
    await exportExcel(url, filterApply, 'compromiso_cinco', 'post');
  }

  const fetchOptions = async (searchTerm: string) => {
    setLoadingOptions(true);
    const dataSend: FetchApiInterface = {
      url: `/comms/get_active_sc_number`,
      data: { scNumber: searchTerm },
    }
    const response = await fetchApi(dataSend);
    setLoadingOptions(false);
    if (response?.data?.status === 'success') {
      const data = response?.data?.data || [];
      if (!data.length) {
        showMessage('NoDataResponse', 'error');
        return [];
      }
      return data.map((item: any) => ({ value: item }));
    } else {
      showMessage(response?.data?.message || 'NoDataResponse', 'error');
    }
    return [];
  };

  useEffect(() => {
    if (searchTerm) {
      fetchOptions(searchTerm).then(newOptions => setOptions(newOptions));
    } else {
      setOptions([]);
    }
  }, [searchTerm]);

  const debouncedFetch = debounce(value => {
    setSearchTerm(value);
  }, 300);

  const openDetailEdit = (e: any) => {
    setScNumber(e?.ascScNumber);
    setOpenDetailSc(true);
  }

  const changeInput = (value: any) => {
    if (!value) {
      setScNumber('');
    } else {
      setScNumber(value);
    }
  }

  return(
    <Layout>
      <Content>
        <Row justify='center'>
          <Col span={24} className='p-1'>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Breadcrumb>
                  <Breadcrumb.Item>{t('planCommitments')}</Breadcrumb.Item>
                  <Breadcrumb.Item>{t('commitment5')}</Breadcrumb.Item>
                </Breadcrumb>
                <Title level={3}>{t('immediateReportCardCases')}</Title>
                <Paragraph strong>
                  {t('monitoringAccessToServices')}
                </Paragraph>
              </Col>
              <Col span={24}>
                <Card className='border-radius'>
                  <Row gutter={[10, 30]}>
                    <Col span={24}>
                      <Row justify='space-between' align="middle">
                        <Col>
                          <Row gutter={[10, 10]} align="middle">
                            <Col>
                              <Filters
                                filterApply={filterApply}
                                onFinish={onFinish}
                                cleanFilters={cleanFilters}
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                              />
                            </Col>
                            {
                              (scopes.includes('COMP_5_SAVE')) && (
                                <Col>
                                  <Spin spinning={loadingOptions}>
                                    <Space size={0}>
                                      <AutoComplete
                                        options={options}
                                        onSearch={debouncedFetch}
                                        onSelect={(value: any) => setScNumber(value)}
                                        style={{ width: 200 }}
                                        value={scNumber}
                                      >
                                        <Input onChange={(e) => changeInput(e?.target?.value) } placeholder={t('scNumber')} style={{ width: '100%' }} />
                                      </AutoComplete>
                                      <Button
                                        disabled={!scNumber}
                                        onClick={() => setOpenDetailSc(true)}
                                        type="primary"
                                      >
                                        {t('btnNewCase5')}
                                      </Button>
                                    </Space>
                                  </Spin>
                                </Col>
                              )
                            }
                          </Row>
                        </Col>
                        <Col>
                          <Row align='middle' gutter={[10, 10]}>
                            <Col>
                              <Search
                                placeholder={t('searchPlaceholder')}
                                onSearch={handleSearch}
                                allowClear
                                enterButton
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                style={{ width: 250 }}
                              />
                            </Col>
                            <Col>
                              {dropdown}
                            </Col>
                            <Col>
                              <Tooltip title={t('reloadListTooltip')}>
                                <Button icon={<SyncOutlined />} onClick={() => getTabla(0)} />
                              </Tooltip>
                            </Col>
                            {
                              (scopes.includes('COMP_5_DESCARGAR')) && (
                                <Col>
                                  <Button
                                    type='primary'
                                    loading={downloading}
                                    onClick={() => exportExcelHanddle()}
                                  >
                                    {t('exportExcel')}
                                  </Button>
                                  <Modal
                                    open={openModalFilterExport}
                                    title={t('exportExcel')}
                                    footer={null}
                                    onCancel={() => setOpenModalFilterExport(false)}
                                  >
                                    <Row gutter={[10, 10]}>
                                      <Col span={24}>
                                        <Form form={form} layout='vertical'>
                                          <DpaSelected
                                            form={form}
                                            name='dpasExcel'
                                            setFilterApply={setFilterApplyExcel}
                                            filterApply={filterApplyExcel}
                                            isRequired={true}
                                          />
                                        </Form>
                                      </Col>
                                      <Col span={24}>
                                        <Space>
                                          <Button
                                            type='primary'
                                            ghost
                                            danger
                                            onClick={() => setOpenModalFilterExport(false)}
                                          >
                                            {t('cancel')}
                                          </Button>
                                          <Button
                                            type='primary'
                                            onClick={exportExcelHanddle}
                                          >
                                            {t('export')}
                                          </Button>
                                        </Space>
                                      </Col>
                                    </Row>
                                  </Modal>
                                </Col>
                              )
                            }
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Typography.Title level={5}>
                        {t('scTableResult')} ({ pagination.total })
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <Table dataSource={dataSource} columns={visibleColumns} scroll={{ x: 1200 }} pagination={pagination} onChange={handleTableChange} />
                    </Col>
                  </Row>
                </Card>
                <DrawerProjectC2 open={openDrawer} setOpenDrawer={setOpenDrawer} item={itemRegister} />

                {/*Presentar el detalle del SC*/}
                <DetailSc5 openDetailSc={openDetailSc} setOpenDetailSc={setOpenDetailSc} scNumber={scNumber} getTabla={getTabla} />

              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Compromiso5;
