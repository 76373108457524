import {Button, Col, Empty, Input, message, Popover, Row, Space, Spin, Typography, Grid} from "antd";
import {CaretRightOutlined, MoreOutlined, CaretDownOutlined, CloudUploadOutlined} from "@ant-design/icons";
import { useState} from "react";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import useFetch from "../../../hooks/useFetch";
import {DpaInterface} from "../../../interfaces/DpaInterface";
import {useTranslation} from "react-i18next";
import useMessageUser from "../../../hooks/useMessageUser";
import {hasAccess} from "../../../utils";

const { useBreakpoint } = Grid;


const { Paragraph } = Typography;

function RegionItem({ data }: { data: DpaInterface }) {

  const { dpaId: id, dpaName, statusRegister, dpaLatitude, dpaLongitude, createdAt, count } = data;
  const { md } = useBreakpoint();

  const { t } = useTranslation();
  const [name, setName] = useState<string>(dpaName);
  const [latitude, setLatitude] = useState<any>(dpaLatitude);
  const [longitude, setLongitude] = useState<any>(dpaLongitude);

  const [status, setStatus] = useState<boolean>(statusRegister);
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<string | null>(null);
  const [editLatitude, setEditLatitude] = useState<string | null>(null);
  const [editLongitude, setEditLongitude] = useState<string | null>(null);

  const [itemDataList, setItemDataList] = useState<Array<DpaInterface>>( []);
  const { fetchApi, loading, downloadFile } = useFetch();
  const { showMessage }  = useMessageUser();

  const setValuesEdit = () => {
    setEdit(name);
    setEditLatitude(latitude);
    setEditLongitude(longitude);
  }

  const downloadCurrentTemplate = () => {
    message.info(t('processDownloadLocation'));
    downloadFile(`catalogs/dpa/donwload_dpa/${data.dpaId}`, {}, 'get').then(
      (response: any) => {
        if (response.status === 200 ) {
          const binaryData  = [];
          const filename    = `${data.dpaName}_location.xlsx`;

          const file = new File([response.data], filename);

          binaryData.push(file);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: response.data.type }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        } else {
          message.error(t('processDownloadLocationError'));
        }
      }
    ).catch(e => {
      message.error(t('processDownloadLocationError'));
    })
  }

  const actions = () => {
    return(
      <Space>
        <Button size='small' type='primary' className="btn-border-radius" onClick={setValuesEdit}>{ t('dpaEditButton') }</Button>
        <Button size='small' type='ghost' className="btn-border-radius" danger={status} onClick={deleteItem}>
          { (status) ? t('dpaDesactiveButton') : t('dpaActiveButton') }
        </Button>
        {
          (data.dpaType.dtyName === 'Country') && (
            <Button size='small' type='primary' ghost className="btn-border-radius" onClick={downloadCurrentTemplate}>
              <CloudUploadOutlined />{ (!md)? null : ` ${t('dpaDownloadLocations')}` }
            </Button>
          )
        }
      </Space>
    );
  }

  const getRegisters = () => {
    const dataSend: FetchApiInterface = {
      url: '/catalogs/dpa/get_dpa',
      method: 'POST',
      data: {
        dpaFatherId: id,
      }
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          setItemDataList(data.data);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const deleteItem = () => {
    const dataSend: FetchApiInterface = {
      url: '/catalogs/dpa/update_dpa',
      data: {
        dpaId: data.dpaId,
        statusRegister: !status
      }
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          setStatus(!status);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const getList = () => {
    if (edit !== null) {
      return;
    }
    if (!open) {
      getRegisters();
    }
    setOpen(!open);
  }

  const saveUpdate = () => {
    if (!Boolean(edit)) {
      message.error('Campo requerido.')
      return;
    }
    var pattern = /^[\d.-]+$/;
    if (editLatitude?.length && !pattern.test(editLatitude)) {
      message.error('Valor latitud no es correcta.');
      return;
    }
    if (editLongitude?.length && !pattern.test(editLongitude)) {
      message.error('Valor longitude no es correcta.');
      return;
    }

    const dataSend: FetchApiInterface = {
      url: 'catalogs/dpa/update_dpa',
      method: 'POST',
      data: {
        ...data,
        dpaName: edit,
        dpaLatitude: editLatitude,
        dpaLongitude: editLongitude,
      }
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          message.success(data.message);
          if (edit) {
            setName(edit);
            setLatitude(editLatitude);
            setLongitude(editLongitude);
          }
          setEdit(null);
        } else {
          message.error(data.message);
        }
      }
    ).catch((reason: any) => {
      const msg = (reason?.message && reason.message.includes('code_')) ? reason?.message : 'Server error.';
      message.error(msg);
    });
  }

  return(
    <>
      <Row
        className='region-item'
      >
        <Col flex='auto' onClick={getList}>
          <Spin spinning={loading}>
            <Space>
              {
                (open) ? <CaretDownOutlined /> : <CaretRightOutlined />
              }
              {
                (edit !== null) ? (
                  <Space>
                    <Input readOnly defaultValue={name} />
                    <Input defaultValue={latitude} placeholder='Latitude' onChange={e => setEditLatitude(e.target?.value)} />
                    <Input defaultValue={longitude} placeholder='Longitude' onChange={e => setEditLongitude(e.target?.value)} />
                    <Button type='primary' onClick={saveUpdate}>{t('dpaFormSave')}</Button>
                  </Space>
                ) : (
                  <Paragraph className='m-0 no-selected-user' style={{ color: (status ? '' : 'red') }}>
                    <b>{ count }</b> { name } <small className='text-gray'>{ createdAt?.substr(0, 10) }</small>
                    {
                      (latitude && longitude) && (
                        <>
                          &nbsp;| <b> LAT: </b> { latitude }
                          <b> LNG: </b> { longitude }
                        </>
                      )
                    }

                  </Paragraph>
                )
              }
            </Space>
          </Spin>
        </Col>
        {
          (hasAccess(['ROLE_USER', 'ROLE_GUEST'])) && (
            <Col>
              <Popover
                title="Subnivel"
                placement="left"
                content={actions}
              >
                <Button shape='circle' icon={<MoreOutlined />} />
              </Popover>
            </Col>
          )
        }
      </Row>
      {
        (open) && (
          <>
            {
              (itemDataList.length > 0) ? (
                <Row style={{ marginLeft: '1rem' }} >
                  <Col span={24}>
                    {
                      itemDataList.map((value, index) => (
                        <RegionItem data={value} key={index}/>
                      ))
                    }
                  </Col>
                </Row>
              ) : (
                <Empty />
              )
            }
          </>
        )
      }
    </>
  );
}

export default RegionItem;
