import {useEffect, useState} from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
  Select,
  Dropdown,
  Menu, Checkbox, Badge, Tooltip
} from "antd";
import {EllipsisOutlined, FileExcelOutlined, SyncOutlined} from "@ant-design/icons";
import useFetch from "../../../../../hooks/useFetch";
import {FetchApiInterface} from "../../../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../../../interfaces/Response.interface";
import useMessageUser from "../../../../../hooks/useMessageUser";
import {IMonitoring} from "../../models";
import useExport from "../../../../../hooks/comms/useExport";
import DpaSelected from "../../../../Components/DpaSelected";
import useCommunityWorker from "../../hooks/useCommunityWorker";
import {useTranslation} from "react-i18next";
import {getScopes} from "../../helperComms";

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const Monitoring = () => {

  const { t } = useTranslation();
  const scopes = getScopes();

  const initialColumns = [
    {
      title: t('communityWorker'),  // Reemplaza 'Community Worker'
      dataIndex: 'assCommunityWorker',
      key: 'assCommunityWorker',
      visible: true,
      fixed: 'left',
    },
    {
      title: t('pu'),  // Reemplaza 'PU'
      dataIndex: 'assPuCode',
      key: 'assPuCode',
      visible: true,
    },
    {
      title: t('total'),  // Reemplaza 'Total'
      dataIndex: 'total',
      key: 'total',
      visible: true,
    },
    {
      title: t('updated'),  // Reemplaza 'Actualizados'
      dataIndex: 'actualizados',
      key: 'actualizados',
      visible: true,
    },
    {
      title: t('pending'),  // Reemplaza 'Pendientes'
      dataIndex: 'pendientes',
      key: 'pendientes',
      visible: true,
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [filterApply, setFilterApply] = useState<any>({});
  const [dataFilters, setDataFilters] = useState<any>({});
  const [searchText, setSearchText] = useState<string>('');
  const [columns, setColumns] = useState<Array<any>>(initialColumns);
  const [dataSource, setDataSource] = useState<Array<IMonitoring>>([]);
  const { exportExcel, downloading } = useExport();
  const { fetchApi, loading } = useFetch();
  const { showMessage }  = useMessageUser();
  const {communityWorkers} = useCommunityWorker();
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 20,
    total: 0,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (!searchText) {
      const newFilterApply = { ...dataFilters };
      delete newFilterApply.search;
      setDataFilters(newFilterApply);
    }
  }, [searchText]);

  useEffect(() => {
    getTabla();
  }, [dataFilters]);

  const handleTableChange = (pagination: any) => {
    getTabla(pagination.current);
  };

  const getTabla = (page = 1) => {
    const dateSend = {
      ...dataFilters,
    };
    const dataSend: FetchApiInterface = {
      url: `/comms/get_monitoreo?page=${page - 1}`,
      data: dateSend,
    }
    fetchApi(dataSend).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setDataSource(dataResponse?.content || []);
          setPagination({
            ...pagination,
            current: data?.data?.pageable?.pageNumber + 1,
            total: data?.data?.totalElements,
          });
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const handleSearch = (value: string) => {
    if (value) {
      setSearchText(value);
      setDataFilters((prevFilters: any) => ({ ...prevFilters, search: value }));
    }
  };

  // Función para manejar el cambio en el estado de visibilidad de las columnas
  const handleVisibleChange = (e: any, columnName: any) => {
    const newColumns = columns.map(column => {
      if (column.key === columnName) {
        return { ...column, visible: e.target.checked };
      }
      return column;
    });
    setColumns(newColumns);
  };

  const visibleColumns = columns.filter(column => column.visible);

  // Menú para el Dropdown con Checkboxes para cada columna
  const menu = (
    <Menu>
      {columns.map(column => (
        <Menu.Item key={column.key}>
          <Checkbox
            checked={column.visible}
            onChange={e => handleVisibleChange(e, column.key)}
          >
            {column.title}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  // El Dropdown que muestra el menú
  const dropdown = (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button type='primary' shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );

  const exportData = async () => {
    const dataSend = {
      isAfiliated: true,
      cinSync: true,
      cinDonwload: false,
    };
    await exportExcel('/comms/donwload_excel_monitoreo', dataSend);
  }

  const onFinish = (values: any) => {
    let filters = {...filterApply, communityWorker: values?.communityWorker};
    if (values?.dpa === undefined) {
      delete filters?.puCode;
    }
    setDataFilters(filters);
    setOpenModal(false);
  }

  const cleanFilters = () => {
    setSearchText('');
    form.resetFields();
    setFilterApply({});
    setDataFilters({});
    setOpenModal(false);
  }

  return(
    <Row justify='center'>
      <Col span={24} className='p-1'>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Title level={3}>{t('sciScuUpdateMonitoring')}</Title>
          </Col>
          <Col span={24}>
            <Card className='border-radius'>
              <Row gutter={[10, 30]}>
                <Col span={24}>
                  <Row justify='space-between'>
                    <Col>
                      <Title level={5}>{t('currentFyUpdate')}</Title>
                    </Col>
                    {
                      (scopes.includes('MON_DESCARGAR')) && (
                        <Col>
                          <Button shape='round' type='primary' icon={<FileExcelOutlined />} loading={downloading} onClick={exportData}>{t('exportExcel')}</Button>
                        </Col>
                      )
                    }
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify='space-between'>
                    <Col>
                      <Badge dot={(Object.keys(dataFilters)?.length > 0)} >
                        <Button type='primary' ghost icon={ <FileExcelOutlined /> } onClick={() => setOpenModal(true)}>
                          {t('filters')}
                        </Button>
                      </Badge>
                      <Modal width={600} title={t('filters')} open={openModal} onCancel={() => setOpenModal(false)} footer={
                        <Row justify='space-between'>
                          <Col>
                            <Button shape='round' type='primary' danger ghost onClick={cleanFilters}>{t('clearFilters')}</Button>
                          </Col>
                          <Col>
                            <Space>
                              <Button shape='round' type='text' onClick={() => setOpenModal(false)}>{t('cancel')}</Button>
                              <Button shape='round' type='primary' onClick={() => form.submit() }>{t('applyFilters')}</Button>
                            </Space>
                          </Col>
                        </Row>
                      }>
                        <Row>
                          <Col span={24}>
                            <Form layout='vertical' form={form} onFinish={onFinish}>
                              <Row gutter={[10, 0]}>
                                <Col span={24}>
                                  <DpaSelected form={form} name='puCode' maxLevel={3} setFilterApply={setFilterApply} filterApply={filterApply} isRequired={false} />
                                </Col>
                                <Col span={24}>
                                  <Form.Item name='communityWorker' label={t('facilitator')}>
                                    <Select allowClear showSearch>
                                      {
                                        communityWorkers.map((worker: any, index) => (
                                          <Option key={index} value={worker?.assCommunityWorker}>{worker?.assCommunityWorker}</Option>
                                        ))
                                      }
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </Modal>
                    </Col>
                    <Col>
                      <Row align='middle' gutter={[20, 20]}>
                        <Col>
                          <Search placeholder={t('searchPlaceholder')} onSearch={handleSearch} allowClear enterButton value={searchText} onChange={(e) => setSearchText(e.target.value) } />
                        </Col>
                        <Col>
                          { dropdown }
                        </Col>
                        <Col>
                          <Tooltip title={t('reloadListTooltip')}>
                            <Button icon={<SyncOutlined />} onClick={() => getTabla(0)} />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Table dataSource={dataSource} columns={visibleColumns} scroll={{ x: 800 }} pagination={pagination} onChange={handleTableChange} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Monitoring;
