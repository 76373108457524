import {message} from "antd";
import useFetch from "../useFetch";
import {useState} from "react";

function useExport() {

  const { downloadFile }  = useFetch();

  const [downloading, setDownloading] = useState(false);

  const exportExcel = async (url: string, dataSend: any = {}, fileName: string = '', method: string = 'post') => {
    setDownloading(true);
    try {
      const response = await downloadFile(url, dataSend, method);
      if (response.status !== 200) {
        message.error('Error al descargar el archivo.');
        return;
      }

      let filename = fileName;
      filename = `${filename ? filename : 'export'}_${new Date().toISOString().replace(/[:.]/g, '_')}_file.xlsx`;
      const file = new File([response.data], filename, { type: response.data.type });
      const downloadLink = document.createElement('a');

      downloadLink.href = window.URL.createObjectURL(new Blob([file]));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink); // Limpieza del enlace
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      console.error("Error al exportar el archivo", error);
      message.error('Ocurrió un error durante la descarga del archivo.');
    }
  };

  const exportXML = async (url: string, dataSend: any = {}, fileName: string = '', method: string = 'post') => {
    setDownloading(true);
    try {
      const response = await downloadFile(url, dataSend, method);
      if (response.status !== 200) {
        message.error('Error al descargar el archivo.');
        return;
      }

      // Usamos un nombre de archivo predeterminado específico para XML
      const defaultFilename = `export_${new Date().toISOString().replace(/[:.]/g, '_')}_file.xml`;
      const filename = fileName || defaultFilename;

      // Creamos un nuevo archivo con el tipo MIME adecuado para XML
      const file = new File([response.data], filename, { type: 'text/xml' });
      const downloadLink = document.createElement('a');

      downloadLink.href = window.URL.createObjectURL(new Blob([file], { type: 'text/xml' }));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink); // Limpieza del enlace
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      console.error("Error al exportar el archivo", error);
      message.error('Ocurrió un error durante la descarga del archivo.');
    }
  };

  const exportZIP = async (url: string, dataSend: any = {}, fileName: string = '', method: string = 'post') => {
    setDownloading(true);
    try {
      const response = await downloadFile(url, dataSend, method);
      if (response.status !== 200) {
        message.error('Error al descargar el archivo.');
        return;
      }

      // Usamos un nombre de archivo predeterminado específico para ZIP
      const defaultFilename = `export_${new Date().toISOString().replace(/[:.]/g, '_')}_file.zip`;
      const filename = fileName || defaultFilename;

      // Creamos un nuevo archivo con el tipo MIME adecuado para ZIP
      const file = new File([response.data], filename, { type: 'application/zip' });
      const downloadLink = document.createElement('a');

      downloadLink.href = window.URL.createObjectURL(new Blob([file], { type: 'application/zip' }));
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink); // Limpieza del enlace
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      console.error("Error al exportar el archivo", error);
      message.error('Ocurrió un error durante la descarga del archivo.');
    }
  };


  return {
    exportExcel,
    exportXML,
    exportZIP,
    downloading,
  };
}

export default useExport;
